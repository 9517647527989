import { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import { ToastContainer } from "react-toastify";
import ReactGA from "react-ga4";
import { flatRoutesList } from "@/Routes";
import { IntegrationProvider, ManualChargeProvider } from "@/hooks";
import { theme } from "@/styles/theme";
import Fonts from "@/styles/Fonts";
import "react-toastify/dist/ReactToastify.css";
import "@/styles/datePicker.css";
import "antd/dist/antd.css";
import "@/styles/global.scss";
import { AuthProvider } from "./contexts";
import { Helmet } from 'react-helmet';
import { capitalizeFirstLetter, getApplicationName } from "./utils";
import { PreviewContextProvider } from "./contexts/PreviewContext";
function MyApp() {
  const applicationName = getApplicationName({})
  const routeComponents = flatRoutesList().map(({ path, element }, key) => (
    <Route path={path} element={element} key={key} />
  ));

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("barte-user-info"));
    if (user?.email === "devtestes@gmail.com") {
      console.log(user?.email);
    }
    //@ts-ignore
    if (window?.userGuiding) {
      //@ts-ignore
      window?.userGuiding?.identify(user?.email ? user?.email : "login@paginalogin.com", {
        name: user?.email ? user?.email : "login@paginalogin.com",
        email: user?.email ? user?.email : "login@paginalogin.com",
        created_at: new Date(),
      })
    }
    if (!user || user.email.includes("@barte")) return;
    ReactGA.initialize([
      {
        trackingId: String(import.meta.env.VITE_GA_MEASUREID),
        gaOptions: {
          userId: user?.email,
        },
      },
    ]);
    ReactGA.set({ userId: user?.email });
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, [window.location.pathname]);


  useEffect(() => {
    //@ts-ignore
    const faviconLink = document?.getElementById('favicon');
    faviconLink?.setAttribute('href', `/src/favicon-${import.meta.env.VITE_APPLICATION_NAME}.png`);
  }, []);

  return (
    <>
      <ChakraProvider theme={theme}>
        <Helmet>
          <title>{capitalizeFirstLetter(import.meta.env.VITE_APPLICATION_NAME)}</title>
          <link id="favicon" rel="icon" />
        </Helmet>
        <Fonts />
        <BrowserRouter>
            <AuthProvider>
              <ManualChargeProvider>
                <IntegrationProvider>
                <PreviewContextProvider>

                  <ToastContainer />
                  {/* <LoadPage /> */}
                  <Routes>{routeComponents}</Routes>
                  </PreviewContextProvider>

                </IntegrationProvider>
              </ManualChargeProvider>
            </AuthProvider>
        </BrowserRouter>
      </ChakraProvider>
    </>
  );
}

export default MyApp;
